<template>
  <v-form
    ref="form"
    class="rcc-regions-form"
    v-model="valid"
    @submit.prevent="onSubmitForm"
  >
    <div class="rcc-regions-form__form-body">
      <rcc-text-input
        label="Название"
        width="520px"
        v-model="form.name"
        :rules="[value => isRequiredField({ value, field: 'Название' })]"
      />

      <h2>Бронирование</h2>

      <rcc-number-input
        class="number-input"
        width="520px"
        label="min кол-во паллет"
        :min="0"
        v-model="form.intermediate_booking_min_pallet"
      />

      <rcc-number-input
        class="number-input"
        width="520px"
        label="Оптимальное кол-во паллет"
        :rules="[bookingSoftLimitValidation]"
        v-model="form.booking_soft_limit_pallet"
      />

      <rcc-number-input
        class="number-input"
        width="520px"
        label="max кол-во паллет"
        :min="0"
        v-model="form.intermediate_booking_max_pallet"
      />
    </div>

    <rcc-footer-buttons :is-submit="isSubmit" @cancel-click="$router.push({ name: 'regions' })"/>
  </v-form>
</template>

<script>
import RegionsApi from '@/api/regions'
import Page from '@/mixins/page'
import Form from '@/mixins/form'

import RccTextInput from 'Components/controls/rcc-text-input'
import RccFooterButtons from 'Components/layouts/rcc-footer-buttons'
import RccNumberInput from 'Components/controls/rcc-number-input'

export default {
  components: {
    RccTextInput,
    RccFooterButtons,
    RccNumberInput
  },

  mixins: [RegionsApi, Page, Form],

  data() {
    return {
      form: {
        name: '',
        intermediate_booking_min_pallet: 0,
        intermediate_booking_max_pallet: 0
        // consolidation_booking_min_pallet: 0,
        // consolidation_booking_max_pallet: 0
      }
    }
  },

  methods: {
    bookingSoftLimitValidation(value) {
      return value > this.form.intermediate_booking_min_pallet
        && value < this.form.intermediate_booking_max_pallet
        || 'Должно быть больше min кол-ва паллет и меньше max кол-ва паллет'
    }
  }
}
</script>

<style lang="scss" scoped>
.rcc-regions-form {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__form-body {
    flex-grow: 1;
    padding: $base-form-padding;
  }

  &__booking-section {
    display: flex;
    gap: 20px;
  }

  .number-input {
    flex: none;

    &_small {
      max-width: 250px;
    }
  }

  &__form-section {
    margin: 10px 0;
  }
}
</style>
